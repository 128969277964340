
import "./BetButton.css"

const BetButton = ({children, action, active}) => (
    <button className={`BetButtonX  ${active && "BetButtonX--jump"}`} onClick={() => action()}>
        <div className={`BetButtonX-border ${active && "BetButtonX-border--shine"}`}/>
        <div className="BetButtonX-content">{children}</div>
    </button>
)


export default BetButton;