
const ChameleonIcon = () => (
    <svg className={"orbe-icon-chameleon"} viewBox="0 0 132.29166 132.29167">
    <g>
        <path
        d="M 305.68555 121.24609 L 308.25391 144.36523 C 225.3406 107.52079 156.02367 153.72721 133.57617 197.79688 C 99.241961 251.57993 124.27995 352.06091 188.54883 372.98633 C 220.37564 384.83141 272.49986 395.48723 312.87695 369.39062 C 352.40187 346.36502 351.73483 308.47953 341.64844 284.10742 C 331.23253 259.09316 301.57889 255.48639 282.05273 256.36328 C 251.0327 255.12423 236.61121 275.61785 234.27344 290.78516 C 231.70314 307.70336 237.13284 316.79822 247.63086 326.74805 C 272.36034 343.42841 295.16739 331.68767 302.56641 317.23047 C 310.04581 302.6162 297.27965 295.02193 287.18945 297.97852 C 292.12958 306.30405 289.44016 310.33052 282.05273 314.54688 C 274.576 317.32215 264.88642 317.05288 258.54688 309.53711 C 252.76076 296.51622 256.12004 289.3745 266.125 282.56641 C 280.9811 275.63378 295.19575 273.03534 312.49219 283.46484 C 326.97553 293.48944 336.15655 314.49899 316.98633 338.05078 C 296.72638 361.67753 280.03132 358.50717 258.41992 353.97852 C 216.51077 337.2608 215.10864 318.52946 210.64062 308.25391 C 206.52339 281.88112 207.49067 248.31473 257.39062 230.67578 C 310.25483 213.3425 338.40196 234.54795 361.16992 263.55664 C 371.53608 274.04672 377.61133 257.90625 377.61133 257.90625 L 350.38086 225.02539 L 405.86719 227.59375 C 398.65218 156.92853 362.14764 126.62249 305.68555 121.24609 z M 354.74805 157.72266 A 20.293348 20.293348 0 0 1 375.04102 178.01562 A 20.293348 20.293348 0 0 1 354.74805 198.31055 A 20.293348 20.293348 0 0 1 334.45508 178.01562 A 20.293348 20.293348 0 0 1 354.74805 157.72266 z " transform="scale(0.26458333)"/>
        <circle
        cx="94.26828"
        cy="46.420498"
        r="1.5632087" />
    </g>
    </svg>
) 

export default ChameleonIcon