
const QuestionIcon = () => (
    <svg className={"orbe-icon-question"} viewBox="0 0 512 512">
        <g transform="matrix(1.2002805,0,0,1.2002805,-67.715035,-64.13657)">
            <path
                transform="matrix(0.78882825,0,0,0.78882825,-34.676107,-15.144803)"
                d="M 299.9749,255.0025 H 233.38564 C 225.42151,147.65137 340.25345,64.099234 459.65981,119.238 c 69.61412,31.90462 99.06696,123.73605 64.64977,197.18177 -18.6385,31.80688 -37.9579,35.97239 -59.15453,49.13383 -15.39781,10.7512 -41.53583,19.73835 -42.9921,53.6593 l -0.6465,45.25483 H 353.6342 v -32.32488 c 1.26938,-25.76984 -4.16169,-67.65922 47.84082,-96.32814 l 20.36468,-10.99046 c 21.0619,-10.30794 42.1026,-22.56774 52.36631,-60.44754 5.21962,-32.88149 -3.36314,-75.57775 -57.86154,-93.4189 -73.53293,-22.12598 -120.43215,36.06987 -116.36957,84.04469 z"
            />
            <path
                d="m 441.60001,586.97144 a 55.771427,55.771427 0 0 1 -55.49255,55.77073 55.771427,55.771427 0 0 1 -56.04752,-55.21299 55.771427,55.771427 0 0 1 54.93204,-56.3229 55.771427,55.771427 0 0 1 56.59687,54.64972"
                transform="matrix(0.62826867,0,0,0.62826867,28.8951,64.494485)"
            />
        </g>
    </svg>
)

export default QuestionIcon

