
import { NEAR_NETWORK } from "../utils/configurations";
import "./Footer.css"

const Footer = () => (
    <div className="Footer">
        <div className="Footer-content">Lucky Pet BETA | Powered by NEAR-PROTOCOL | network: {NEAR_NETWORK}</div>
    </div>
)


export default Footer;