import React from "react";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom";
import { WalletSelectorContextProvider } from "./contexts/WalletSelectorContext";

async function init() {
  return null;
}

const initializeNear = async () => {
  try {
    await init();
    renderApp();
  } catch (error) {
    console.error('Error initializing NEAR:', error);
  }
};

initializeNear();

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <WalletSelectorContextProvider>
        <App/>
      </WalletSelectorContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};