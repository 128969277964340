import "./Instructions.css"

const Instructions = ({guessCounter}) => (
    <div className={`Instructions ${guessCounter === 3 ? "Intructions--right" : ""}`}>
        {guessCounter === 0 && <>Type a number to choose a color</>}
        {guessCounter === 1 && <>Type a number to choose an adjective</>}
        {guessCounter === 2 && <>Type a number to choose an animal</>}
        {guessCounter === 3 && <>BET</>}
    </div>
)

export default Instructions