

const HummingbirdIcon = () => (
    <svg className={"orbe-icon-hummingbird"} viewBox="0 0 132.29166 132.29167">
    <g>
        <path
        d="M 55.467227,8.729351 C 47.986274,20.271459 45.752698,31.813878 46.501566,43.355985 L 14.039231,23.569548 C 9.0889355,40.165127 28.06143,73.191902 54.848855,79.21863 l -15.457831,1.855118 c -1.449986,22.981162 -0.04523,28.340202 8.038101,43.283122 8.07209,-3.09472 16.220351,-5.72967 23.495934,-13.60345 l -8.038102,-8.03812 -12.366708,0.30919 C 70.228982,91.316138 84.95864,78.861 88.238744,64.688354 95.324003,49.7164 112.97927,56.447722 120.0827,60.359747 113.20183,52.344579 103.53712,54.65435 98.057433,44.102017 96.061151,41.96312 94.166695,39.569154 88.922791,39.126261 c -13.737186,0.396751 -18.24042,3.489431 -23.871534,7.93922 l -0.309182,-4.637052 5.565347,-6.183724 z m 35.485565,36.802008 a 2.7587128,2.7587128 0 0 1 2.758328,2.759061 2.7587128,2.7587128 0 0 1 -2.758328,2.758324 2.7587128,2.7587128 0 0 1 -2.759062,-2.758324 2.7587128,2.7587128 0 0 1 2.759062,-2.759061 z" />
    </g>
    </svg>
)

export default HummingbirdIcon

