

const BearIcon = () => (
    <svg className="orbe-icon-bear" viewBox="0 0 132.29166 132.29167">
    <g>
        <path
        d="M 359.69336 114.91797 C 349.56348 115.79094 345.37903 123.68977 345.58789 136.78125 C 332.2926 137.51156 319.57867 137.07596 301.1543 148.06445 C 273.65641 159.31033 255.01785 153.33961 239.125 146.5332 C 208.93591 133.60195 88.919926 140.63517 52.191406 268.51172 C 42.469341 315.00172 46.552196 362.07526 52.542969 407.25586 L 102.9707 406.55078 C 128.64504 333.868 183.16478 332.97683 234.44531 325.58984 C 234.63326 303.3751 241.77192 281.40602 255.98047 263.38867 C 246.57232 286.41721 243.95726 335.82408 254.48438 406.69727 L 295.51172 406.55078 C 300.26188 326.46963 332.96821 267.33871 470.12891 191.87891 L 470.77344 160.4082 L 443.26758 158.99805 C 435.80324 158.176 429.72941 152.1887 423.16797 145.94922 C 404.86183 131.54949 391.63235 128.86202 372.38672 130.43359 C 370.7666 119.51611 366.00249 115.51963 359.69336 114.91797 z M 411.31641 152.73047 A 6.6155083 6.6155083 0 0 1 418.0293 159.21289 L 418.03125 159.3457 A 6.6155083 6.6155083 0 0 1 411.44727 165.96094 A 6.6155083 6.6155083 0 0 1 404.79883 159.41016 A 6.6155083 6.6155083 0 0 1 411.31641 152.73047 z "
        transform="scale(0.26458333)"/>
        <path
        d="m 138.23483,381.513 c -2.31682,-5.6732 -1.36224,-11.61404 2.82112,-14.81087 16.97283,-13.30391 31.95375,-21.41921 53.60126,-22.56895 1.89207,21.79923 7.1817,41.89968 13.75295,61.35933 l -50.25118,0.70528 c -7.0448,-7.72399 -14.64556,-14.75304 -19.92415,-24.68479 z"
        transform="scale(0.26458333)"/>
        <path
        d="m 332.97026,322.91429 c -4.32575,9.80953 -3.31731,20.66124 0.19619,32.90613 l 20.78069,48.63189 43.5495,-0.10328 c -11.35543,-50.01186 -10.39266,-97.05041 -5.71375,-144.49678 -21.00529,19.14463 -47.20244,39.48262 -58.81263,63.06204 z"
        transform="scale(0.26458333)"/>
    </g>
    </svg>
)

export default BearIcon

