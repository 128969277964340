
const PigIcon = () => (

    <svg className={"orbe-icon-pig"} viewBox="0 0 132.29166 132.29167">
    <g>
        <path
        d="M 290.51172 116.08398 C 206.72093 115.88044 109.82087 165.71861 79.195312 273.91406 C 47.410211 278.12842 53.258883 289.46865 43.59375 300.07031 C 56.265962 300.69875 70.781054 299.02374 79.921875 304.06641 L 57.761719 366.18555 L 72.65625 379.26367 C 79.259731 381.66998 88.493412 381.54791 96.996094 376.35742 C 134.54469 350.39999 188.45839 318.00805 222.32812 322.5918 L 228.43164 384.53711 L 259.90039 384.41211 L 263.74219 313.87305 C 291.84566 310.48208 320.48601 311.64663 349.47461 315.32812 L 347.50391 384.31055 L 383.29102 384.55859 L 417.77148 276.82031 C 454.04873 173.22426 379.70837 116.30066 290.51172 116.08398 z M 96.359375 308.03125 A 8.1988772 8.1988772 0 0 1 104.55859 316.23047 A 8.1988772 8.1988772 0 0 1 96.359375 324.42773 A 8.1988772 8.1988772 0 0 1 88.160156 316.23047 A 8.1988772 8.1988772 0 0 1 96.359375 308.03125 z "
        transform="scale(0.26458333)"/>
        <path d="m 43.782731,92.400903 c 2.476474,-1.858304 5.805906,-3.190383 9.495049,-3.780436 l -0.439584,12.220483 -8.176294,-1e-5 z"/>
        <path d="m 74.905394,85.631284 0.06998,15.228856 9.050078,0 c 2.3665,-4.835437 4.216844,-10.129673 4.946685,-14.965114 -5.494667,-0.419943 -9.978623,-0.781499 -14.066741,-0.263742 z"/>
        <path d="M 426.14844 135.94141 C 423.07679 135.92672 420.04968 137.24741 416.81055 139.66406 C 412.6288 143.76831 412.31641 146.51363 412.21289 150.79688 C 411.77212 154.54866 414.34818 159.26956 418.86914 162.7793 C 415.19219 166.3518 410.61403 167.38626 405.91797 168.95117 L 412.0918 178.875 C 418.43959 176.17516 424.90377 173.99795 430.00391 165.68359 C 435.96418 168.44256 443.59004 167.02191 451.42383 164.47266 C 444.80168 164.94419 438.69014 163.6583 434.60156 160.96289 C 439.28236 158.02063 442.26924 146.99764 437.14258 141.47852 C 433.16813 137.68866 429.62964 135.95805 426.14844 135.94141 z M 427.09766 145.35156 A 3.509734 3.509734 0 0 1 430.60742 148.86133 A 3.509734 3.509734 0 0 1 427.09766 152.37109 A 3.509734 3.509734 0 0 1 423.58789 148.86133 A 3.509734 3.509734 0 0 1 427.09766 145.35156 z " transform="scale(0.26458333)"/>
    </g>
    </svg>
)

export default PigIcon


