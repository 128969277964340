import { useEffect, useRef } from 'react'
import "./BetInput.css"

function BetInput({setFunction, guess}) {
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)

    const setFocus = (number) => {
        if (number === 1)
            ref1.current.focus()
        if (number === 2)
            ref2.current.focus()
        if (number === 3)
            ref3.current.focus()
    }

    useEffect(() => {
        setFocus(1)
    },[])

    return (
        <div className="BetInput">
            <input
                type="number"
                className="BetInput-number"
                defaultValue={guess[0]}
                ref={ref1}
                onClick={() =>{
                    if (guess[0] !== null && guess[0] !== "")
                        setFocus(2)
                }}
                onChange={(e) => {
                    e.target.value = e.target.value.slice(-1)
                    const val = e.target.value ? parseInt(e.target.value) : ""
                    setFunction([val, guess[1], guess[2]])

                    if (val !== "")
                        setFocus(2)
                }}
            />
            <input
                type="number"
                className="BetInput-number"
                defaultValue={guess[1] ? guess[1] : ""}
                ref={ref2}
                onClick={() =>{
                    if (guess[0] === null || guess[0] === "")
                        setFocus(1)
                    if (guess[1] !== null && guess[1] !== "")
                        setFocus(3)
                }}
                onKeyDown={(e) => {
                    if(e.keyCode === 8 && (guess[1] === null || guess[1] === ""))
                        setFocus(1)
                }}
                onChange={(e) => {
                    e.target.value = e.target.value.slice(-1)
                    const val = e.target.value ? parseInt(e.target.value) : ""
                    setFunction([guess[0], val, guess[2]])

                    if (val !== "")
                        setFocus(3)
                }}
            />
            <input
                type="number"
                className="BetInput-number"
                defaultValue={guess[2] ? guess[2] : ""}
                ref={ref3}
                onClick={() =>{
                    if (guess[0] === null || guess[0] === "") {
                        setFocus(1)
                        return
                    }
                    if (guess[1] === null || guess[1] === "")
                        setFocus(2)
                }}
                onKeyDown={(e) => {
                    if(e.keyCode === 8 && (guess[2] === null || guess[2] === ""))
                        setFocus(2)
                }}
                onChange={(e) => {
                    e.target.value = e.target.value.slice(-1)
                    const val = e.target.value ? parseInt(e.target.value) : ""
                    setFunction([guess[0], guess[1], val])
                }}
            />
        </div>
    );
  }

  export default BetInput;