import { ADJECTIVES, ANIMALS, COLORS, CONTRACT_ID } from "./configurations"

export const CONTRACT_FUNCTIONS = {
    GET_INFO: {
        request_type: "call_function",
        account_id: CONTRACT_ID,
        method_name: "get_info",
        args_base64: "",
        finality: "optimistic",
    }
}

export const guessPhrase = (guess) => {
    if (guess[0] === "" && guess[1] === "" && guess[2] === "")
      return "Choose numbers to fill the orbe!"

    const color = COLORS[guess[0]]?.["name"] ? COLORS[guess[0]]["name"] : ""
    const adjective = ADJECTIVES[guess[1]] ? ADJECTIVES[guess[1]] : ""
    const animal = ANIMALS[guess[2]] ? ANIMALS[guess[2]] : ""
    return `${color} ${adjective} ${animal}`
}

const isOneDigitNumber = (str) => str !== null && str !== "" && !isNaN(str)
export const guessesFilled = (guess) => {
    let counter = 0
    guess.forEach(g => {
        if (isOneDigitNumber(g))
            counter++
    });

    return counter
}