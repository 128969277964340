
const CatIcon = () => (
    <svg className={"orbe-icon-cat"} viewBox="0 0 132.29166 132.29167">
        <g>
            <g transform="matrix(0.97719898,0,0,0.97719898,10.084938,0.2028557)">
            <path
                transform="matrix(-0.23055732,0,0,0.23055732,113.10919,13.887851)"
                d="m 388.06445,57.253906 c -6.56065,0.12713 -7.08028,8.01816 0.54493,12.921875 61.23125,53.331349 -10.84017,109.081039 -32.79493,108.964839 -57.62385,-3.10008 -109.86674,5.54324 -141.05664,15.86915 -51.79016,12.57104 -61.19574,-35.33475 -96.44726,-57.12696 -6.61495,-14.46132 -13.45102,-19.53367 -19.572269,-22.21679 l -1.232422,23.80273 c -14.516708,4.57311 -26.860333,13.06033 -36.675781,26.0957 l -6.550781,11.74024 c -5.621885,3.49777 -7.301309,6.7475 -4.759766,12.51953 l 5.580078,12.76758 c 4.936959,10.15529 13.31264,7.19469 19.660157,6.17187 25.254254,0.57561 39.155994,13.5342 43.726564,36.67383 25.95986,67.06055 59.89862,66.08739 77.93359,75.81836 21.42287,6.02433 16.14869,27.92019 35.61719,48.31055 l 1.05859,16.92773 c -0.94743,3.17091 1.54393,6.7286 -3.8789,8.10938 -6.58193,1.92823 -12.52928,4.30173 -9.875,12.69531 l 36.32226,0.35351 c 3.71846,-2.79541 3.90811,-17.94033 -1.76367,-53.60156 -3.50747,-5.4968 -4.87875,-30.56183 -5.64258,-43.375 28.82592,0.3515 64.48414,-11.92912 93.8418,-3.91016 25.17527,6.87656 50.63572,23.86785 60.3457,40.91211 -2.69223,9.65071 -3.64815,25.15902 -6.07812,40.22657 -4.99669,0.0457 -12.06524,2.92501 -16.92578,8.46289 -1.36978,0 -7.30151,7.02652 -1.76368,10.93164 l 38.78907,-0.35156 c 4.15804,-22.56892 4.87697,-45.13809 18.6914,-67.70704 0.0385,-14.40131 -3.13789,-23.16389 -14.81054,-38.4375 -7.99318,-6.60604 -13.51855,-15.61646 -21.51172,-37.02734 -1.40175,-15.75909 -5.01529,-28.75382 -10.17578,-39.81445 -1.01817,-6.18716 -0.6272,-11.54734 4.88672,-13.08203 46.80869,-36.97043 62.44928,-112.32845 0,-152.339848 -2.13147,-0.929671 -3.96843,-1.314493 -5.48243,-1.285156 z M 76.169922,165.74023 a 6.1711977,5.289598 0 0 1 6.171875,5.29102 6.1711977,5.289598 0 0 1 -6.171875,5.28906 6.1711977,5.289598 0 0 1 -6.171875,-5.28906 6.1711977,5.289598 0 0 1 6.171875,-5.29102 z"/>
            <path
                transform="matrix(-0.87139776,0,0,0.87139776,113.10919,13.887851)"
                d="m 36.661444,79.652923 c 3.087207,3.286079 5.998466,5.868845 12.044647,6.154199 1.966876,1.523897 0.03441,3.839048 -0.439585,4.92336 -1.516574,1.197878 -5.404898,8.325134 -8.176293,12.660068 l -1.055006,4.22002 H 28.48515 c -0.52046,-0.88058 -0.4759,-1.87416 0.175834,-2.98918 1.281679,-1.39097 3.846876,-1.74465 6.066284,-1.4946 l 1.582508,-10.550049 z"/>
            <path transform="matrix(-0.87139776,0,0,0.87139776,113.10919,13.887851)"
                d="m 74.289977,107.61057 9.495049,0.0879 10.198388,-11.16548 c 2.881378,-3.223628 1.829969,-7.414343 1.494592,-10.637971 -4.943384,-1.56102 -9.927702,-2.958305 -15.561331,-1.758342 1.876801,4.372388 5.982632,7.823099 4.83544,13.275485 l -3.868353,5.538778 c -3.10624,0.3182 -4.038917,0.50732 -5.53878,1.05501 -0.749279,0.93778 -2.178671,2.66682 -1.055005,3.6046 z"/>
            </g>
        </g>
    </svg>
)

export default CatIcon

