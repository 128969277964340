// @ts-nocheck
import "./App.css";
import { BrowserRouter} from "react-router-dom"
import { useCallback, useEffect, useState } from "react";
import "@near-wallet-selector/modal-ui/styles.css";
import { Contract, utils, providers } from "near-api-js";
import Card, { CardCol, CardRow } from "./components/Card";
import Orbe from "./components/Orbe/Orbe";
import BetInput from "./components/BetInput";
import PrizesList from "./components/PrizesList";

import {
  CONTRACT_ID,
  GET_INFO_INTERVAL,
} from "./utils/configurations";
import { MY_NEAR_WALLET_CONFIG } from "./utils/walletConfigs";

import { getHash } from "./utils/transactions";
import StatusModal from "./components/StatusModal";
import { CONTRACT_FUNCTIONS, guessesFilled, guessPhrase } from "./utils/game";
import Instructions from "./components/Instructions";
import BetButton from "./components/BetButton";
import Footer from "./components/Footer";
import NearLogo from "./components/NearLogo"
import { useWalletSelector } from "./contexts/WalletSelectorContext";


//http://localhost:3000/?signMeta=%5Bobject+Object%5D&transactionHashes=Gwb16VHioBWa5t152Rozng8nfmnHMBz6CnN7SaCdZx5R

//http://localhost:3000/?signMeta=%5Bobject+Object%5D&transactionHashes=pUXLNgjUWDTrQcPedLTv8bapDjHn19nJZGMV71PCSy4

export interface iInfo{
  betValue: string
  feeValue: string
  prizes: iInfoPrize[]
  description: string
  disclaimer: string
  counter: number
  accountBalance: string
  fees: string
  feePercentage: string
}

export interface iInfoPrize {
  name: string
  description: string
  chance: string
  value: string
  priceCalc: string
  counter: number
}

const BOATLOAD_OF_GAS = utils.format.parseNearAmount("0.00000000003")!;

function App({ }: { }): any {
  const [account, setAccount] = useState<Account | null>(null);
  const [info, setInfo] = useState(null)
  const [transactionStatus, setTransactionStatus] = useState(null)
  const [loading, setLoading] = useState(false)
  const closeStatusModal = () => {setTransactionStatus(null)}

  const { selector, modal, wallet, accounts, accountId } = useWalletSelector()

  let [guess, setGuess] = useState(["", "", ""])

  const getAccount = useCallback(async (): Promise<Account | null> => {
    if (!accountId) {
      return null;
    }

    const { network } = selector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

    return provider
      .query<AccountView>({
        request_type: "view_account",
        finality: "final",
        account_id: accountId,
      })
      .then((data) => ({
        ...data,
        account_id: accountId,
      }));
  }, [accountId, selector]);

  useEffect(() => {
    if (!accountId) {
      return setAccount(null);
    }

    setLoading(true);

    getAccount().then((nextAccount) => {
      //console.log(nextAccount)
      setAccount(nextAccount);
      setLoading(false);
    });
  }, [accountId, getAccount]);


  //console.log(currentUser)
  
  // const luckyGem = new Contract(
  //   wallet.account(),
  //   CONTRACT_ID,
  //   {
  //     viewMethods: ["get_info"],
  //     changeMethods: ["bet"],
  //     useLocalViewExecution: false,
  //     sender: wallet.account()
  //   }
  // );
  

  const getInfo = async ():iInfo => {
    try {
      const { network } = selector.options;
      const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });
  
      const res = await provider.query( CONTRACT_FUNCTIONS.GET_INFO );
  
      setInfo(JSON.parse(Buffer.from(res.result).toString()));
    } catch (error) {
      console.error("Error fetching info:", error);
    }
  };

  const signOut = async () => {
    const wallet = await selector.wallet();

    wallet.signOut().catch((err) => {
      console.log("Failed to sign out");
      console.error(err);
    });
  };

  const bet = async () => {
    let errors = [];

    for(let i = 0; i <= 2; i++) {
      if(!guess[i] || parseInt(guess[i]) < 0 || parseInt(guess[i]) > 9) {
        errors.push("invalid number " + (guess[i] + 1))
      }
    }
    if (errors.length === 0) {
      const wallet = await selector.wallet();

      return wallet
        .signAndSendTransaction({
          signerId: accountId!,
          actions: [
            {
              type: "FunctionCall",
              params: {
                contractId: CONTRACT_ID,
                methodName: "bet",
                args: {guess: guess},
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0.5"),
              },
            },
          ],
        }).then(res => console.log(res)).catch(error => console.log(error));
    } else {
      console.log(errors)
    }
  }

  // const bet = async () => {
  //     //const { contract } = selector.store.getState();
  //     const wallet = await selector.wallet();

  //     console.log(guess)

  //       return wallet
  //         .signAndSendTransaction({
  //           signerId: accountId!,
  //           actions: [
  //             {
  //               type: "FunctionCall",
  //               params: {
  //                 contractId: CONTRACT_ID,
  //                 methodName: "bet",
  //                 args: {guess: guess},
  //                 gas: BOATLOAD_OF_GAS,
  //                 deposit: utils.format.parseNearAmount("0.5"),
  //               },
  //             },
  //           ],
  //         })
  //         .catch((err) => {
  //           alert("Failed to add message");
  //           console.log("Failed to add message");

  //           throw err;
  //         });
  //   }




  const getPalette = () => {
    if (typeof guess[0] === "undefined" || guess[0] === "")
      return "LuckyGem--palette_"

    return `LuckyGem--palette_${guess[0]} LuckyGem--palette_${guess[0] < 5 ? "hot": "cold"}`
  }

  useEffect(() => {
    getHash().then(status => {
      if (status) {
        console.log(status)
        setTransactionStatus(status)
        window.history.replaceState({}, '', window.location.pathname);
      }
    })

    getInfo();
    const iId = setInterval(getInfo, GET_INFO_INTERVAL);

    return () => clearInterval(iId)
  }, []);

   return (
    <BrowserRouter>
      <div className={`App LuckyGem ${getPalette()}`}>
        {transactionStatus && <StatusModal status={transactionStatus} closeAction={closeStatusModal}/>}
        <div className="navbar-container">
          <div className="ProfileBar">
            <span />
            <NearLogo/>
            <button className="user-button" onClick={() => !accountId ? modal.show() : signOut()}>
              {!!accountId ? `${accountId} | Sign Out` : "Login with NEAR"}
            </button>
          </div>
        </div>
          <Card>
            <CardRow>
              <CardCol>
                <div className="CardTitle">{guessPhrase(guess)}</div>
              </CardCol>
            </CardRow>
            <CardRow>
              <CardCol small>
                <div>
                  <Orbe guess={guess}/>
                  <BetInput setFunction={setGuess} guess={guess}/>
                </div>
              </CardCol>
              <CardCol>
                <PrizesList prizes={info?.prizes} />
              </CardCol>
            </CardRow>
            <CardRow>
              <CardCol>
                {!accountId && <button className="BetButton" onClick={modal.show}>Login to play!</button>}
                  {(accountId && guessesFilled(guess) === 3) && <BetButton action={bet} active={guessesFilled(guess) === 3}>BET | {info?.betValue}</BetButton>}
                  {(accountId && guessesFilled(guess) < 3) && <Instructions guessCounter={guessesFilled(guess) }/>}
              </CardCol>
            </CardRow>
          </Card>
          <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
