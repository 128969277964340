import { providers } from "near-api-js";
import {
    CONTRACT_ID,
  } from "./configurations";


    const searchHashes = () => {
    if (window.location.search !== "") {
      const hashes = window.location.search.split("transactionHashes=")
  
      if (hashes.length === 1) {
        return []
      }
  
      return hashes[1].split("&")
    }
  
    return []
  }

  export const getHash = () => {
    const hashes = searchHashes()
    const provider = new providers.JsonRpcProvider(
      "https://archival-rpc.testnet.near.org"
    );
    
    return new Promise((resolve) => {
        if (hashes.length === 0) {
            resolve(null)
            return
        }

        console.log("hash found -> " + hashes[0])
        provider.txStatus(hashes[0], CONTRACT_ID).then((response) => {
            if (response && response?.receipts_outcome?.length > 0 && response.receipts_outcome[0]?.outcome?.logs.length > 0) {
                console.log("log found!!!")
                resolve (JSON.parse(response.receipts_outcome[0].outcome.logs[0]))
            } else {
                console.log("no game log found")
                resolve(null)
            }
        });
    })
  }
