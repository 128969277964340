
const RabbitIcon = () => (
    <svg className={"orbe-icon-rabbit"} viewBox="0 0 132.29166 132.29167">
    <g>
        <path
        d="M 186.60742 114.25391 C 144.40068 126.17579 128.61105 155.79938 127.57812 197.60547 C 90.212282 215.72752 82.846132 245.89296 82.603516 272.22461 C 94.40431 283.97778 136.5194 286.55752 157.64844 273.10742 C 159.58088 292.04093 178.39138 308.26432 197.47852 315.92188 C 185.19147 278.80917 197.56651 225.30306 254.60547 211.54102 C 206.7516 239.97338 198.47123 284.76026 214.4043 325.08984 C 190.79519 329.38274 163.26318 336.17953 167.15039 366.70117 L 292.69141 335.66992 C 358.77402 317.03346 399.84259 216.83474 327.25 160.05469 C 274.57175 120.17807 198.96184 163.55889 189.01562 194.61328 L 175.61523 193.9082 C 176.41547 169.31818 202.24443 183.7105 186.60742 114.25391 z M 130.2832 217.42578 A 8.5347234 7.9392776 0 0 1 138.94531 225.20508 L 138.94727 225.36523 A 8.5347234 7.9392776 0 0 1 130.45508 233.30273 A 8.5347234 7.9392776 0 0 1 121.87695 225.44336 A 8.5347234 7.9392776 0 0 1 130.2832 217.42578 z "
        transform="scale(0.26458333)"/>
        <path
        d="m 305.22234,344.71011 c 13.40745,-7.03374 29.0325,-12.95869 37.54361,-22.4406 l 42.85828,0.53415 c -4.31924,39.80378 -41.78638,30.33391 -80.40189,21.90645 z"
        transform="matrix(0.26458333,0,0,0.26458333,-0.06597492,0.46182447)"/>
        <path
        d="m 371.0627,191.33683 c 6.7685,9.06658 7.05859,25.10975 7.87909,40.78522 3.99659,3.32213 10.23735,-0.0216 12.48846,-3.65475 13.97306,-18.04647 9.64413,-38.35256 -0.6625,-53.38735 z"
        transform="scale(0.26458333)"/>
        <path
        d="m 119.36341,191.4224 -11.03513,1.82369 c -1.68937,-28.625 -3.48835,-59.37214 18.62207,-81.14995 7.13305,1.31402 12.60834,5.94357 15.09568,16.54902 -28.33942,28.15284 -22.63361,46.19774 -22.68262,62.77724 z"
        transform="scale(0.26458333)"/>
    </g>
    </svg>
)

export default RabbitIcon

