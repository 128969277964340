
const NearLogo = () => (
    <svg className="NearLogo" viewBox="0 0 1080 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1080" height="1080"/>
    <path d="M773.425 243.38C751.453 243.38 731.054 254.772 719.544 273.499L595.538 457.606C591.499 463.673 593.138 471.854 599.206 475.893C604.124 479.172 610.631 478.766 615.11 474.913L737.172 369.042C739.2 367.217 742.327 367.403 744.152 369.431C744.98 370.361 745.42 371.561 745.42 372.794V704.265C745.42 707.003 743.206 709.2 740.468 709.2C738.997 709.2 737.611 708.558 736.682 707.425L367.707 265.758C355.69 251.577 338.045 243.397 319.47 243.38H306.575C271.673 243.38 243.38 271.673 243.38 306.575V773.425C243.38 808.327 271.673 836.62 306.575 836.62C328.546 836.62 348.946 825.228 360.456 806.501L484.462 622.394C488.501 616.327 486.862 608.146 480.794 604.107C475.876 600.828 469.369 601.234 464.89 605.087L342.828 710.958C340.8 712.783 337.673 712.597 335.848 710.569C335.02 709.639 334.58 708.439 334.597 707.206V375.651C334.597 372.913 336.811 370.715 339.549 370.715C341.003 370.715 342.406 371.358 343.335 372.49L712.259 814.242C724.276 828.423 741.921 836.603 760.496 836.62H773.392C808.293 836.637 836.603 808.361 836.637 773.459V306.575C836.637 271.673 808.344 243.38 773.442 243.38H773.425Z" fill="#fff"/>
    </svg>
) 

export default NearLogo



