


export const GET_INFO_INTERVAL = 120000

console.log("NETWORK", process.env.REACT_APP_NEAR_NETWORK)

export const NEAR_NETWORK = process.env.REACT_APP_NEAR_NETWORK === "mainnet" ? "mainnet" : "mainnet"
export const CONTRACT_ID = NEAR_NETWORK === "mainnet" ? "luckygem.monkeymage.near" : "monkeymage.testnet"

export const COLORS = [
    {name: "Red", color: "#b50000"},
    {name: "Orange", color: "#b57300"},
    {name: "Golden", color: "#a78903"},
    {name: "Lemon", color: "#007100"},
    {name: "Teal", color: "#01837f"},
    {name: "Aegean", color: "#2893b0"},
    {name: "Indieblue", color: "#3c3dd4"},
    {name: "Nebula", color: "#6d2bbd"},
    {name: "Pink", color: "#b40258"},
    {name: "Black", color: " #000000"}
]

export const ADJECTIVES = [
    "Inspiring",
    "Luminous",
    "Majestic",
    "Vibrant",
    "Revitalizing",
    "Uplifting",
    "Exhilarating",
    "Invigorating",
    "Heartening",
    "Optimistic"
];

export const ANIMALS = [
    "Bull", "Pig", "Cat", "Snake", "Hummingbird", "Dolphin", "Rabbit", "Chameleon", "Dog", "Bear"
]

