import "./PrizesList.css"

const PrizesList = ({prizes}) => {
    const instructions = "For every bet, the blockchain generates 3 random numbers. Guess them in the right order and gain a prize.";

    return <div className="PrizesList">
        <div className="PrizeList-instructions">{instructions}</div>
        {prizes && prizes.length > 0 && prizes.map((prize) => {
            return <div key={prize.name} className="PrizeList-item">
                <div className="PrizeList-description">guess {prize.correctGuesses} </div>
                <div className="PrizeList-prize" title={prize.priceCalc}> {prize.value.substring(0, 5)} NEAR</div>
            </div>
        })}
    </div>
}

export default PrizesList;