import "./Icons/Icons.css"
import "./Orbe.css"
import "./OrbeEffect_0.css"
import "./OrbeEffect_1.css"
import "./OrbeEffect_2.css"
import "./OrbeEffect_3.css"
import "./OrbeEffect_4.css"
import "./OrbeEffect_5.css"
import "./OrbeEffect_6.css"
import "./OrbeEffect_7.css"
import "./OrbeEffect_8.css"
import "./OrbeEffect_9.css"
import BullIcon from "./Icons/BullIcon"
import PigIcon from "./Icons/PigIcon"
import HummingbirdIcon from "./Icons/HummingbirdIcon"
import RabbitIcon from "./Icons/RabbitIcon"
import ChameleonIcon from "./Icons/ChameleonIcon"

import DogIcon from "./Icons/DogIcon"
import CatIcon from "./Icons/CatIcon"
import SnakeIcon from "./Icons/Snake"
import BearIcon from "./Icons/BearIcon"
import DolphinIcon from "./Icons/DolphinIcon"
import QuestionIcon from "./Icons/QuestionIcon"

const Orbe = ({guess}) => {

    const icon = guess[2]
    const effect = guess[1]

    return <div className={`Gem Gem--palette_${guess[0]} Gem--icon_${icon}`}>
        <div className="orbe">
        <div className={`orbe-back orbe-back--effect_${effect}`}/>

        <div className={`orbe-bubble orbe-bubble_${effect} orbe-bubble_${effect}--1`}></div>
        <div className={`orbe-bubble orbe-bubble_${effect} orbe-bubble_${effect}--2`}></div>
        <div className={`orbe-bubble orbe-bubble_${effect} orbe-bubble_${effect}--3`}></div>
        <div className={`orbe-bubble orbe-bubble_${effect} orbe-bubble_${effect}--4`}></div>
        <div className={`orbe-bubble orbe-bubble_${effect} orbe-bubble_${effect}--5`}></div>
        <div className="orbe-icon">
            {icon === "" && <QuestionIcon/>}

            {icon === 0 && <BullIcon/>}

            {icon === 1 && <PigIcon/>}

            {icon === 2 && <CatIcon/>}

            {icon === 3 && <SnakeIcon/>}

            {icon === 4 && <HummingbirdIcon/>}

            {icon === 5 && <DolphinIcon/>}

            {icon === 6 && <RabbitIcon/>}

            {icon === 7 && <ChameleonIcon/>}

            {icon === 8 && <DogIcon/>}
            
            {icon === 9 && <BearIcon/>}
        </div>
        </div>
    </div>
}


export default Orbe
