import { guessPhrase } from "../utils/game";
import Orbe from "./Orbe/Orbe";
import "./StatusModal.css"

const StatusModalBetNumber = ({drawned, guess}) => (
    <div className={`StatusModal-betNumber ${drawned !== guess ? "StatusModal-betNumber--wrong": ""}`}>{guess}</div>
)

const StatusModal = ({status, closeAction}) => {

    return <>
        <div className="StatusModalBackground"/>
        <div className="StatusModal">
            <div className={`StatusModalBorder ${status.won && "StatusModalBorder--shine"}`}/>
            <div className="StatusModalContent">
                <div className="StatusModalHeader">
                {status.won ? "Congratulations!" : "Better luck next time"} <button className="StatusModalClose" onClick={() => closeAction()}>X</button>
                </div>
                <div className="StatusModalBody">
                    {status.won && <>
                        <p>You guessed correctelly {`${status.correctGuesses} number${status.correctGuesses > 1 ? "s": ""}!`}<br/>
                        WON {status.prize}!</p>
                    </>}
                    {!status.won && <>Unfortunally, none of your numbers got drawn.</>}
                        <p>The drawn animal was <br/>
                        {guessPhrase(status.luckyNumbers)}</p>
                        <div className={`LuckyGem--palette_${status.luckyNumbers[0]}`}>
                            <Orbe guess={status.luckyNumbers}/>
                        </div>
                        <p className="StatusModal-drawnedBet">
                            <div className="StatusModal-drawnedBetText">drawned:</div><div className="StatusModal-drawnedBetNumber">{`${status.luckyNumbers[0]} ${status.luckyNumbers[1]} ${status.luckyNumbers[2]}`}</div> 
                            <div className="StatusModal-drawnedBetText">bet:</div><div className="StatusModal-drawnedBetNumber">
                                <StatusModalBetNumber drawned={status.luckyNumbers[0]} guess={status.guess[0]}/>
                                <StatusModalBetNumber drawned={status.luckyNumbers[1]} guess={status.guess[1]}/>
                                <StatusModalBetNumber drawned={status.luckyNumbers[2]} guess={status.guess[2]}/>
                            </div>
                        </p>
                        
                    
                </div>
            </div>
        </div>
    </>
}


export default StatusModal;