import "./Card.css"

const Card = (props) => (
    <div className="Card">
      {props.children}
    </div>
)

export const CardRow = (props) => (
  <div className="CardRow">
    {props.children}
  </div>
)
export const CardCol = (props) => (
  <div className={`CardCol ${props.small ? "small" : ""}`}>
    {props.children}
  </div>
)

export default Card;