
const DolphinIcon = () => (
    <svg className={"orbe-icon-dolphin"} viewBox="0 0 132.29166 132.29167">
        <g>
        <g transform="matrix(0.2085832,0,0,0.2085832,12.748487,12.748541)">
            <path
            d="M 504.357,369.342 C 532.909,267.177 478.126,187.529 395.608,150.28 371.734,120.91 354.685,75.199 321.171,64.027 c -32.898,-10.97 -4.28229,35.509796 -31.034,63.21 -1.24,-0.014 -2.494,-0.236 -3.714,-0.042 -90.293,14.062 -147.67057,49.65441 -183.11757,121.34041 -13.798004,22.162 -11.042599,37.38686 -27.120599,51.69786 C 41.502798,314.23764 8.7252529,359.61442 0,386.265 c 44.041,6.278 80.542,-11.962 80.542,-11.962 18.254,23.861 53.896,39.592 58.969,37.901 18.246,-37.901 -36.501,-75.816 30.888,-129.15 29.6426,-18.24171 45.614,-21.061 79.08,-21.061 9.321,0 33.113,2.252 44.152,6.411 10.61331,-10.6285 15.22758,-12.90114 35.18941,-21.69401 -32.50107,15.91147 -93.42085,103.59401 -62.80181,104.65292 50.916,0 86.7124,-16.18091 110.9404,-25.95891 2.211,2.037 4.442,4.123 6.708,6.258 6.362,5.988 12.877,12.246 19.627,18.746 16.736,16.113 44.291,30.728 51.325,53.203 20.27,64.707 58.165,49.966 49.738,22.592 -8.407,-27.373 -9.668,-22.196 0,-56.861 z m -51.831,-37.568 c -6.002,0 -10.867,-4.866 -10.867,-10.86 0,-6.002 4.864,-10.853 10.867,-10.853 5.994,0 10.846,4.851 10.846,10.853 0,5.994 -4.852,10.86 -10.846,10.86 z"
            transform="translate(-15.221743,-5.0739146)" />
        </g>
        </g>
    </svg>
)

export default DolphinIcon

